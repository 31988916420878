<!--
--------------------------------------------------------------------------------
<copyright file="LaserOee.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.outer_arc') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.outer_arc_oee_txt') }} (<span class="demi-bold">O</span>verall<span
          class="demi-bold"
        >
          E</span
        >quipment<span class="demi-bold"> E</span>fficiency).
      </p>
      <p class="text">{{ $t('widget_info_text.outer_arc_oee_txt1') }} :</p>
      <ul class="list">
        <li class="list-item">{{ $t('widget_info_text.outer_arc_oee_txt_l1') }}</li>
        <li class="list-item">{{ $t('widget_info_text.outer_arc_oee_txt_l2') }}</li>
        <li class="list-item">{{ $t('widget_info_text.outer_arc_oee_txt_l3') }}</li>
      </ul>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.outer_arc_oee_txt_info') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}</p>
      <p class="text tip-bkg">{{ $t('widget_info_text.tooltip_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.inner_arc') }}</summary>
      <p class="text">{{ $t('widget_info_text.inner_arc_oee_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.clock_hand') }}</summary>
      <p class="text">{{ $t('widget_info_text.clock_hand_oee_txt') }}</p>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.clock_hand_info_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-filter />
      <shift-filter />
      <expand-btn />
      <details>
        <summary>
          <span class="list">{{ $t('widget_info_text.more_filter') }} </span>
        </summary>
        <img src="images/info/threeDotsMenu.jpg" class="list" />
        <target-filter />
      </details>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';
import TargetFilterInfo from './TargetFilter.vue';
import Expand from '@/components/Charts/widgetInfo/Expand.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
    'target-filter': TargetFilterInfo,
    'expand-btn': Expand,
  },
})
export default class LaserOeeWidgetInfo extends Vue {}
</script>
